import React from "react";
import CalenderChart from "../Charts/CalenderChart";
// import StackedBarChart from "../Charts/StackedBarChart";

const HistoricalCalendarChart = ({ historicalData, chartDataType = 'pm25', yAxisLabel = '' }) => {
    // console.log("🚀 ~ file: HistoricalCalendarChart.js ~ line 6 ~ HistoricalCalendarChart ~ historicalData", historicalData)

    function getDaysInMonth(year, month) {
        return new Date(year, month, 0).getDate();
    }

    const compileHistoricYearlyData = (type) => {
        if (!historicalData || !Array.isArray(historicalData))
            return []
        let months = {};

        historicalData.forEach(({ ts, ...item }) => {
            const date = new Date(ts)
            // const month = date.toLocaleString('default', { month: 'numeric' })
            const month = date.getMonth() + 1;
            // const year = date.getFullYear();
            const day = date.getDate();

            const value = item[type]?.aqius;
            // const objectValue = { value, ts };
            months = {
                ...months, [month]:
                    months?.[month] ? { ...months?.[month], [day]: value } : { [day]: value }

            }
        });

        let monthData = [];
        const monthNames = { 1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr', 5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug', 9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec' };
        Object.entries(monthNames).forEach(([month, monthName]) => {
            const totalDays = getDaysInMonth(2022, month);
            let dayData = []
            for (let day = 1; day <= totalDays; day++) {
                const dayValue = months[month]?.[day];
                dayData.push(dayValue ? { x: day.toString(), y: dayValue } : { x: day.toString(), y: 0 })
            }
            monthData.push({ name: monthName, data: dayData });
        })
        monthData = monthData.reverse();
        return monthData;
    }
    const data = compileHistoricYearlyData(chartDataType);
    return <><CalenderChart data={data} xAxisLabel="Days" yAxisLabel={yAxisLabel} /></>
}

export default HistoricalCalendarChart