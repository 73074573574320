import React from "react";
import { Link } from "react-router-dom";
import ProgressChart from "../Charts/ProgressChart/ProgressChart";

const Historical = (props) => {
  const { singleItem, id } = props
  let noofdasy = 0
  const weeklydata = singleItem.historical.daily.filter((item) => {
    noofdasy++
    if (noofdasy < 8) {
      return item
    }
  })
  return (
    <div>

      <div className="border overflow-auto" style={{ maxHeight: "240px" }}>
        <table className="table">
          <thead className="thead-light tableThColour">
            {/* leaf let */}
            <tr>
              <td scope="col">Date</td>
              <td scope="col">Pollution level</td>
              <td scope="col">Humidity</td>
              <td scope="col">Tempeature</td>

            </tr>
          </thead>
          <tbody>
            {weeklydata.map((item, index) => {
              return (<tr key={index}>
                <td className="tableTextColour">{new Date(item.ts).toLocaleString().split(",")[0]}</td>
                <td>
                  <ProgressChart value={id == '5fdb0bc345047a4d3d83d677' ? item?.outdoor_station?.aqius : item?.pm25?.aqius} />
                </td>
                <td>
                  <span>
                    {id == '5fdb0bc345047a4d3d83d677' ? item.hm_sum : item?.hm} %rh
                  </span>
                </td>
                <td className="tableTextColour">
                  <span>
                    {" "}
                    {id == '5fdb0bc345047a4d3d83d677' ? item.tp_sum : item?.tp}  <sup>°</sup>C
                  </span>

                </td>

              </tr>)
            })}

            {/*   <tr>
              <td className="fw-bold tableTextColour">Today</td>
              <td>
                <ProgressChart value={100} />
              </td>
              <td>
                <h4 className="ps-4 tableTextColour">
                  <i className="bi bi-cloud-drizzle-fill"></i>
                </h4>
              </td>
              <td className="tableTextColour">
                <b>
                  {" "}
                  21 <sup>°</sup>
                </b>
                16 <sup>°</sup>
              </td>
              <td className="tableTextColour">7.2 km/h</td>
            </tr>
            <tr>
              <td>Tuesday, Nov 8</td>
              <td>
                {" "}
                <ProgressChart value={35} />
              </td>
              <td>
                <h4 className="ps-4 tableTextColour">
                  {" "}
                  <i className="bi bi-cloud-drizzle-fill"></i>
                </h4>
              </td>
              <td className="tableTextColour">
                <b>
                  {" "}
                  21 <sup>°</sup>
                </b>
                16 <sup>°</sup>
              </td>
              <td>7.2 km/h</td>
            </tr>
            <tr>
              <td>Wednesday, Nov 9</td>
              <td>
                {" "}
                <ProgressChart value={10} />
              </td>
              <td>
                {" "}
                <h4 className="ps-4 tableTextColour">
                  {" "}
                  <i className="bi bi-cloud-drizzle-fill"></i>
                </h4>
              </td>
              <td className="tableTextColour">
                <b>
                  {" "}
                  21 <sup>°</sup>
                </b>
                16 <sup>°</sup>
              </td>
              <td>7.2 km/h</td>
            </tr>
            <tr>
              <td>Thursday, Nov 10</td>
              <td>
                {" "}
                <ProgressChart value={75} />
              </td>
              <td>
                {" "}
                <h4 className="ps-4 tableTextColour">
                  {" "}
                  <i className="bi bi-cloud-drizzle-fill"></i>
                </h4>
              </td>
              <td className="tableTextColour">
                <b>
                  {" "}
                  21 <sup>°</sup>
                </b>
                16 <sup>°</sup>
              </td>
              <td>7.2 km/h</td>
            </tr>
            <tr>
              <td>Friday, Nov 11</td>
              <td>
                {" "}
                <ProgressChart value={50} />
              </td>
              <td>
                {" "}
                <h4 className="ps-4 tableTextColour">
                  {" "}
                  <i className="bi bi-cloud-drizzle-fill"></i>
                </h4>
              </td>
              <td className="tableTextColour">
                <b>
                  {" "}
                  21 <sup>°</sup>
                </b>
                16 <sup>°</sup>
              </td>
              <td>7.2 km/h</td>
            </tr>
            <tr>
              <td>Saturday, Nov 11</td>
              <td>
                {" "}
                <ProgressChart value={60} />
              </td>
              <td>
                {" "}
                <h4 className="ps-4 tableTextColour">
                  {" "}
                  <i className="bi bi-cloud-drizzle-fill"></i>
                </h4>
              </td>
              <td className="tableTextColour">
                <b>
                  {" "}
                  21 <sup>°</sup>
                </b>
                16 <sup>°</sup>
              </td>
              <td>7.2 km/h</td>
            </tr>
            <tr>
              <td>Sunday, Nov 11</td>
              <td>
                {" "}
                <ProgressChart value={76} />
              </td>
              <td>
                {" "}
                <h4 className="ps-4 tableTextColour">
                  {" "}
                  <i className="bi bi-cloud-drizzle-fill"></i>
                </h4>
              </td>
              <td className="tableTextColour">
                <b>
                  {" "}
                  21 <sup>°</sup>
                </b>
                16 <sup>°</sup>
              </td>
              <td>7.2 km/h</td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Historical;
