const ColorBar = () => {

    return (
        <>
            <div className="color-container-div">
                <div className="color-bar-main-div">
                    <div className="Good">
                        <span>Good</span>
                    </div>
                    <div className="moderate">
                        <span>Moderate</span>
                    </div>
                    <div className="unhealty-for">
                        <span>Unhealthy for sensitive groups</span>
                    </div>
                    <div className="unhealthy">
                        <span>Unhealthy</span>
                    </div>
                    <div className="very-unhealthy">
                        <span>Very Unhealthy</span>
                    </div>
                    <div className="hazardous">
                        <span>Hazardous</span>
                    </div>
                </div>
            </div>
        </>
    )

}

export default ColorBar