
import { getcolorclass, getfaceicon } from "../../constants"
import ComboChart from "../Charts/ComboChart"
import EmjoiDiv from "./EmjoiDiv"

const PopupData = (props) => {
  const { data } = props
  // console.log('aaaaaa', data?.id == '5fdb0bc345047a4d3d83d677' ? data.historical?.hourly[0]?.outdoor_station?.aqius : data.current.aqius)
  return (
    <>
      <div className="popup-main-div">
        <h6 className="popup-title">{data?.id == '5fdb0bc345047a4d3d83d677' ? data?.settings?.node_name : data?.name}</h6>
        <div className={'popup-head-div popuphead-bg-' + getcolorclass('color', data?.id == '5fdb0bc345047a4d3d83d677' ? data.historical?.hourly[0]?.outdoor_station?.aqius : data.current.aqius)}>
          <div className="popup-aqi-icon">
            {getcolorclass('icon', data?.id == '5fdb0bc345047a4d3d83d677' ? data.historical?.hourly[0]?.outdoor_station?.aqius : data.current.aqius)}
          </div>
          <div className="popup-aqi-text">
            <p>{getcolorclass('text', data?.id == '5fdb0bc345047a4d3d83d677' ? data.historical?.hourly[0]?.outdoor_station?.aqius : data.current.aqius)}</p>
            <p>{data?.id == '5fdb0bc345047a4d3d83d677' ? data.historical?.hourly[0]?.outdoor_station?.aqius : data.current.aqius}µg/m³</p>
          </div>
        </div>
        <div className="popup-time-div">
          <p className="small m-0 font-weight-bold">Updated at</p>
          <p className="small m-0">{new Date(data?.current?.ts).toLocaleString()}</p>
        </div>
        <div class="popup-combo-chart-wrapper">
          <ComboChart yAxisTitle="AQI Value 2.5" singleItem={data} pm={'2.5'} id={data.id} height={100} showXAxis={false} labelSize="60%"/>
          <ComboChart yAxisTitle="AQI Value 10" singleItem={data} pm={'10'} id={data.id + 1} height={100} showXAxis={false} labelSize="60%"/>
        </div>
        <div className="pop-main-emojis">
          <p className="font-weight-bold mb-1 text-center">Last 7 days AQI Stats</p>
          <div className="popup-emojis-div">
            <EmjoiDiv singleItem={data} id={data.id} />
          </div>
        </div>
      </div>
    </>
  )
}

export default PopupData