import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import bok from '../../assets/images/BankOfKhyber.png'

const LeftSidebar = (props) => {
    const { location, data } = props;
    const { locations } = useSelector((state) => state.getapiData);
    // console.log("🚀 ~ file: leftSidebar.js ~ line 11 ~ LeftSidebar ~ props", props)
    const { lat, lang } = location;
    return (
        <>
            <div className="left-loc-div">
                <div className="content">
                    <p>AIR QUALITY MAP</p>
                    <h3>Real-time {data?.name} air pollution Stats</h3>
                    <Link className="pcaa-link" to='/'>See on Map</Link>
                </div>
                <MapContainer center={[lat, lang]} zoom={14} style={{ height: '500px', width: '350px' }} scrollWheelZoom={false}>
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </MapContainer>
            </div>

            {data?.sponseredbybok && <div className="sponser-log-div">
                <h6>THIS MONITORS IS</h6>
                <h5>Powered By</h5>
                <img src={bok} />
            </div>}

            <div className="left-waether-div">
                <div className="head-div">
                    <p>WEATHER</p>
                    <h3>What is the current weather near {data?.name} ?</h3>
                </div>
                <div className="body-div">
                    <table className="table table-borderless">
                        <tbody>

                            <tr>
                                <td>Temperature</td>
                                <td> {data?.current.tp}°C</td>
                            </tr>
                            <tr>
                                <td>Humidity</td>
                                <td> {data?.current.hm}%</td>
                            </tr>
                            <tr>
                                <td>Pressure</td>
                                <td>{data?.current.pr} mbar</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="left-locs-list-div text-left">
                <h6>Explore More</h6>
                <h5>Explore Air Quality Of Other Locations</h5>
                <ul className="loc-list">
                    {locations.map((loc, index) => {
                        return <li key={index}><Link to={'/detailpage/' + loc.url}>{loc.placename}</Link></li>
                    })}
                </ul>
            </div>
        </>
    );
}
export default LeftSidebar