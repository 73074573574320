import React from "react";
import ReactApexChart from "react-apexcharts";
import { GradeType } from "../../constants";

const StackedBarChart = ({ firstSeries, yAxisLabel = '' }) => {
  const options = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      // stackType: "100%"
    },
    plotOptions: {
      bar: {
        horizontal: true
      }
    },
    stroke: {
      width: 1,
      colors: ["#fff"]
    },
    xaxis: {
      title: {
        text: "Days"
      },
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ]
    },
    yaxis: {
      title: {
        text: yAxisLabel,
      }
    },
    colors: [
      function ({ value, seriesIndex, dpIndex, w }) {
        const gradeType = w.config.series[seriesIndex]?.name;
        if (gradeType == GradeType.GOOD) {
          return "#ABD162";
        } else if (gradeType == GradeType.MODERATE) {
          return "#F7D460";
        } else if (gradeType == GradeType.UNHEALTHY_SENSITIVE) {
          return "#F68E49";
        } else if (gradeType == GradeType.UNHEALTHY) {
          return "#F65E5F";
        } else if (gradeType == GradeType.VERY_UNHEALTHY) {
          return "#A070B6";
        } else {
          return "#A06A7B";
        }
      }
    ],
    tooltip: {
      y: {
        formatter: function (val) {
          return val + "";
        }
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 10
    },
    // responsive: [
    //   { breakpoint: 767, options: {} }
    // ]
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={firstSeries}
        type="bar"
        height={350}
      // width={650}
      />
    </>
  );
};

export default StackedBarChart;
