import React from "react";
import { getcolorclass } from "../../../constants";

const ProgressChart = ({ value }) => {
  /* const color = (value) => {
    if (value <= 50) {
      return "danger";
    } else if (value <= 50) {
      return "warning";
    } else if (value <= 75) {
      return "primary";
    } else {
      return "success";
    }
  }; */
  const color = getcolorclass('color', value)
  return (
    <div className="progress" style={{ height: "30px" }}>
      <div
        className={`progress popuphead-bg-${color} h-100 text-white ps-5 fs-6`}
        role="progressbar"
        style={{ width: `${100}%`, alignItems: 'center', justifyContent: 'center' }}
        aria-valuenow="25"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <div className="progress-value-wrapper">
          <b>{color}</b>
          <b>{value}<span className="font-weight-normal pr-1">US AQI</span></b>
        </div>
      </div>
    </div>
  );
};

export default ProgressChart;
