import { dateFormat } from "highcharts"
import { getcolorclass } from "../../constants"

const EmjoiDiv = (props) => {
    const { singleItem, id } = props
    let noofdasy = 0
    const weeklydata = singleItem.historical.daily.filter((item) => {
        noofdasy++
        if (noofdasy < 8) {
            return item
        }
    })
    const formatDate = (date) => {
        return new Date(date).toLocaleDateString("en-US", { day: 'numeric' })
    }
    return (
        <>
            {weeklydata.map((item, index) => {
                return (
                    <div key={index} className="main-div">
                        <span>{formatDate(item?.ts)}th</span>
                        <div className={`single-emoji-div bg-${getcolorclass('color', id == '5fdb0bc345047a4d3d83d677' ? item?.outdoor_station?.aqius : item?.pm25?.aqius)}`}>
                            {getcolorclass('icon', id == '5fdb0bc345047a4d3d83d677' ? item?.outdoor_station?.aqius : item?.pm25?.aqius)}
                        </div>
                        {id == '5fdb0bc345047a4d3d83d677' ? item?.outdoor_station?.aqius : item?.pm25?.aqius}
                    </div>
                )
            })}
        </>
    )
}
export default EmjoiDiv