import React from "react";
import { GradeType } from "../../constants";
import StackedBarChart from "../Charts/StackedBarChart";

const HistoricalBarChart = ({ historicalData, chartDataType = 'pm25', yAxisLabel = '' }) => {

    const compileHistoricYearlyData = (type) => {
        if (!historicalData)
            return
        let months = {};

        historicalData.forEach(({ ts, ...item }) => {
            const date = new Date(ts)
            const month = date.toLocaleString('default', { month: 'numeric' })
            const value = item[type]?.aqius;
            let gradeType = GradeType.GOOD;
            if (value >= 0 && value <= 50) {
            }
            if (value > 50 && value <= 100) {
                gradeType = GradeType.MODERATE;
            }
            if (value > 100 && value <= 150) {
                gradeType = GradeType.UNHEALTHY_SENSITIVE;
            }
            if (value > 150 && value <= 200) {
                gradeType = GradeType.UNHEALTHY;
            }
            if (value > 200 && value <= 300) {
                gradeType = GradeType.VERY_UNHEALTHY;
            }
            if (value > 300) {
                gradeType = GradeType.HAZARDOUS;
            }

            months = {
                ...months, [gradeType]: {
                    ...months?.[gradeType],
                    [month]:
                        months?.[gradeType]?.[month] ? months?.[gradeType]?.[month] + 1 : 1
                }
            }
        });

        const monthsData = Object.entries(months).map(([key, value]) => {
            let modifyMonth = { ...value };
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].forEach(m => {
                if (!value[m]) {
                    modifyMonth = { ...modifyMonth, [m]: 0 }
                }
            })
            let gradeDataCompiled = Object.entries(modifyMonth).map(([key, value]) => value)
            return { data: gradeDataCompiled, name: key };
        })
        return monthsData;
    }
    compileHistoricYearlyData(chartDataType);

    return <div style={{ maxWidth: '750px', width: '100%' }}><StackedBarChart
        firstSeries={compileHistoricYearlyData('pm25')}
        yAxisLabel={yAxisLabel}
    /></div>
}

export default HistoricalBarChart