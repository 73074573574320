import React from "react";
import ReactApexChart from "react-apexcharts";
import { formatTime } from "../../assets/util";

const ComboChart = (props) => {
  const { singleItem, pm, id, height = 350, seriesName = "AQI Value", yAxisTitle = "AQI Value", xAxisLabel = "", showXAxis = true, labelSize = "100%" } = props
  let arrayofdata2 = []
  let hourlyArray = [...singleItem.historical.hourly];
  hourlyArray = hourlyArray.reverse();

  const arrayofdata = hourlyArray.map((item) => {
    // return new Date(item.ts).toLocaleString().split(",")[1]
    return formatTime(item.ts)
    // return item.ts
  })

  if (id == '5fdb0bc345047a4d3d83d677') {
    arrayofdata2 = hourlyArray.map((item) => {
      if (pm == '2.5') {
        return item?.outdoor_station?.aqius
      }
      if (pm == '10') {
        return item?.outdoor_station?.aqicn
      }
    })
  } else {
    arrayofdata2 = hourlyArray.map((item) => {
      if (pm == '2.5') {
        return item?.pm25?.aqius
      }
      if (pm == '10') {
        return item?.pm10?.aqius
      }
    })
  }

  const series = {
    series: [
      {
        name: seriesName,
        data: arrayofdata2
      }
    ],
    options: {
      chart: {
        type: "bar",
        height: height
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: 0,
                to: 50,
                color: "#ABD162"
              },
              {
                from: 51,
                to: 100,
                color: "#F7D460"
              },
              {
                from: 101,
                to: 150,
                color: "#F68E49"
              },
              {
                from: 151,
                to: 200,
                color: "#F65E5F"
              },
              {
                from: 201,
                to: 300,
                color: "#A070B6"
              },
              {
                from: 301,
                to: 2000,
                color: "#A06A7B"
              }
            ]
          },
          columnWidth: "50%"
        }
      },
      dataLabels: {
        enabled: false
      },
      yaxis: {
        title: {
          text: yAxisTitle,
          style: {
            // fontSize: "8px"
          }
        },
        labels: {
          formatter: function (y) {
            return y.toFixed(0) + "";
          },
          style: {
            fontSize: labelSize
          }
        }
      },
      xaxis: {
        // type: "datetime",
        // title: {
        //   text: xAxisLabel,
        // },
        categories: arrayofdata,
        labels: {
          show: showXAxis,
          style: {
            fontSize: "12px"
          }
        }
      },
      // tooltip: {
      //   headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      //   pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      //     '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      //   footerFormat: '</table>',
      //   shared: true,
      //   useHTML: true
      // },
    }
  };
  return (
    <>
      <ReactApexChart
        options={series.options}
        series={series.series}
        type="bar"
        height={height ? height : 350}
        // style={{minHeight: height}}
      />

      {xAxisLabel && <p className="font-weight-bold text-center m-0" style={{position: 'relative', top: -30}}>{xAxisLabel}</p>}
    </>
  );
};

export default ComboChart;
