import React from "react";
import ReactApexChart from "react-apexcharts";

const CalenderChart = ({ data = [], yAxisLabel = '', xAxisLabel = '', }) => {
  const generateData = (value = 10, obj) => {
    let arr = [];
    for (let i = 0; i <= value; i++) {

      arr.push(['jan', i]);
    }
    return arr;
  };

  const series = {
    series: data,
    options: {
      chart: {
        height: 250,
        type: "heatmap",
        zoom: {
          enabled: false
        }
      },
      plotOptions: {
        heatmap: {
          shadeIntensity: 0.5,
          radius: 0,
          useFillColorAsStroke: false,
          colorScale: {
            ranges: [
              {
                from: 0,
                to: 0,
                name: "-",
                color: "#cccccc"
              },
              {
                from: 1,
                to: 50,
                name: "Good",
                color: "#ABD162"
              },
              {
                from: 51,
                to: 100,
                name: "Moderate",
                color: "#F7D460"
              },
              {
                from: 101,
                to: 150,
                name: "Sensitive",
                color: "#F68E49"
              },
              {
                from: 151,
                to: 200,
                name: "Unhealthy",
                color: "#F65E5F"
              },
              {
                from: 201,
                to: 300,
                name: "V Unhealthy",
                color: "#A070B6"
              },
              {
                from: 301,
                to: 1000,
                name: "Hazardous",
                color: "#A06A7B"
              }
            ]
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: 1
      },
      yaxis: {
        title: {
          text: yAxisLabel,
        }
      },
      tooltip: {
        x: {
          show: true,
          format: 'dd MM YYYY',
          formatter: "datetime",
        },
      }
    }
  };
  return (
    <div>
      <ReactApexChart
        series={series.series}
        options={series.options}
        type="heatmap"
        height={350}
      />
      <p className="text-center mb-0" style={{position:'relative', top: '-30px'}}>Days</p>
    </div>
  );
};

export default CalenderChart;
