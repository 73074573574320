import React from "react";
import aqiicon from "../../assets/images/scaleicon.png"
import { getcolorclass, tooltipContent } from "../../constants";
import ColorBar from "../Home/colorBar";
import ToolTip from "../ToolTip";

const OverView = (props) => {
    const { overviewData } = props
    return (
        <>
            <div className="container">
                <div className={'row top-detail-row top-detail-row-' + getcolorclass('color', overviewData.aqi)}>
                    <div className="aqi-value-box">
                        <img src={aqiicon} />
                        <p><span>{overviewData.aqi}</span> US AQI</p>
                    </div>
                    <div className="aqi-text">
                        <h6>LIVE AQI INDEX</h6>
                        <h5>{getcolorclass('text', overviewData.aqi)}</h5>
                    </div>
                    <div className="aqi-icon">
                        {getcolorclass('icon', overviewData.aqi)}
                    </div>
                </div>
                <div className="row overview-stats-div">
                    <div className="mb-3">
                    <ColorBar />
                    </div>
                    <div>
                        <h5 className="heading">Overview</h5>
                        <p className="text">What is the current air quality near {overviewData?.name}?</p>
                    </div>
                    <div className="w-100 overview-table-wrapper">
                        <table className="table current-update-table">
                            <tbody>
                                <tr className="headerrow">
                                    <th> <p className="time-label">Updated On</p></th>
                                    <th> <p className="pm-label">AQI <ToolTip content={tooltipContent.whatIsAQI} label={<i className="bi bi-question-circle"></i>}/></p></th>
                                    <th> <p className="pm-label">PM2.5 <ToolTip content={tooltipContent.pm25} label={<i className="bi bi-question-circle"></i>}/></p></th>
                                    <th style={{minWidth: '100px'}}> <p className="pm-label">PM10 <ToolTip content={tooltipContent.pm10} label={<i className="bi bi-question-circle"></i>}/></p></th>
                                </tr>
                                <tr className="bodyrow">
                                    <td> <p className="time-text">{new Date(overviewData.time).toLocaleString()}</p></td>
                                    <td> <p className="pm-value">{overviewData.aqi} US AQI</p></td>
                                    <td> <p className="pm-value">{overviewData.pm25}</p></td>
                                    <td> <p className="pm-value">{overviewData.pm10}</p></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </>
    )
}

export default OverView