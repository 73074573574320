import { useState } from "react";
import Iframe from 'react-iframe'
import ReactTooltip from 'react-tooltip';

const Export = (props) => {
    const [state, setState] = useState(false)
    const [loader, setLoader] = useState(false)

    const onExportIconClick = () => {
        setState(!state)
        setLoader(true)
    }

    return (
        <div className="export-wrapper">
            <ReactTooltip id="tooltip" className="tooltipContent" />

            <i data-for="tooltip" data-tip={'Export Data'} className="bi bi-cloud-download-fill" onClick={() => onExportIconClick()}></i>
            {state && <div className="iframe-wrapper">
                <h6 className="pt-3 px-2">Export AQI Data</h6>
                {loader && <span className="loader">Loading...</span>}
                <Iframe url="https://pcaakp.org/export/"
                    width="320px"
                    height="250px"
                    styles={{ border: 0, borderRadius: 10 }}
                    className="bg-white"
                    display="block"
                    loading="Loading..."
                    onLoad={() => setLoader(false)}
                    position="relative" />
            </div>}
        </div>
    )
}

export default Export